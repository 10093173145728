// src/redux/listSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Fetch the list data (assuming you're using an async API call)
export const fetchListData = createAsyncThunk('list/fetchListData', async () => {
  const response = await fetch('https://hamiddawod-001-site2.htempurl.com/api/WebContents/List');
  const data = await response.json();
  return data.data; // Extract the 'data' field from the response
});

const listSlice = createSlice({
  name: 'list',
  initialState: {
    list: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchListData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload; // Save the fetched list in the state
      })
      .addCase(fetchListData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default listSlice.reducer;
