import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cartSlice, { setCart } from "../../../store/reducers/cart-slice";

// Load Stripe with your public key
const stripePromise = loadStripe("pk_test_51I7PnfE9ypFo9enALOun4VJ6liBKJkrr1EsxmCfas57xbMo6zj0ZP5472a5jcyy5Yyy6hNYyuMc79vdDrMi7IRcR00759gNMpH");

const PaymentComponent = ({ amount, onStripePay, showModal, handleClose }) => {
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.get(`https://hamiddawod-001-site2.htempurl.com/api/v1/Order/GetSecret?amount=${amount}`);
        setClientSecret(response.data);
      } catch (err) {
        console.error("Failed to fetch client secret", err);
      }
    };

    fetchClientSecret();
  }, [amount]);

  if (!clientSecret) {
    return <p>Loading payment details...</p>; // Show a loading indicator while fetching the client secret
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Complete Your Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentForm amount={amount} onStripePay={onStripePay} handleClose={handleClose} />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

const PaymentForm = ({ amount, onStripePay, handleClose }) => {
  const stripe = useStripe();
  const { cart, user } = useSelector((s) => s);
  const dispatch = useDispatch();
  const elements = useElements();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Customize confirmation params if needed
        return_url: window.location.origin + "/order-history", // Redirect to order-history page
      },
      redirect: 'if_required',
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      handleClose();
      dispatch(setCart([]));
      navigate("/order-history");
      onStripePay();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        disabled={!stripe}
        style={{
          background: "orange",
          border: "none",
          marginTop: "20px",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginLeft: "8%",
          fontFamily: "Roboto",
          fontWeight: "normal",
        }}
      >
        Pay<span> ${amount}</span>
      </Button>
    </form>
  );
};

// const ParentComponent = ({ amount, onStripePay }) => {
//   const [showModal, setShowModal] = useState(false);

//   const handleOpen = () => setShowModal(true);
//   const handleClose = () => setShowModal(false);

//   return (
//     <div>
//       {/* Button to open the payment modal */}
//       <Button variant="primary" onClick={handleOpen}>
//         Open Payment Modal
//       </Button>

//       {/* PaymentComponent rendered inside modal */}
//       <PaymentComponent 
//         amount={amount} 
//         onStripePay={onStripePay} 
//         showModal={showModal} 
//         handleClose={handleClose} 
//       />
//     </div>
//   );
// };

export default PaymentComponent;
