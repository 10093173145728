// // Help.js
// import React from "react";

// const Help = () => {
//   return (
//     <div className="container" style={{width:"70%", marginTop:"40px"}}>
//       <h1>Help</h1>
//       <p style={{marginTop:"20px"}}>
//         Need assistance? We're here to help! If you have any questions,
//         concerns, or feedback regarding Tolo Cart, please don't hesitate to
//         reach out to our customer support team. We're available 24/7 to provide
//         you with the support you need for a seamless food delivery experience.
//       </p>
//     </div>
//   );
// };

// export default Help;

import React from "react";
import { useSelector } from "react-redux";

const Help = () => {
  const list = useSelector((state) => state.list.list);
  const contentItem = list.find((item) => item.typeId === 4); // Assuming 2 is the typeId for Terms and Conditions

  if (!contentItem) return <p>No content available</p>;

  return (
    <div className="container mt-4" style={{textAlign:"center"}}>
      <h1>{contentItem.title}</h1>
      <hr
        className='mb-4'
        style={{
          width:"8%",
          position:"relative",
          margin:"auto",
          height:"5px",
          border:"0",
          backgroundColor:"#ff5a00",
          opacity:"1"
        }}
      ></hr>
      <div dangerouslySetInnerHTML={{ __html: contentItem.content }} />
    </div>
  );
};

export default Help;

