import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Nav, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fb, google } from "../../../assets/images";
import { onLogin } from "../../../services/api/auth-api-actions";
import GoogleLoginButton from "../../buttons/btn-google-login";
import OTPModal from "../otp-modal";
import "./login.css"; // Import the CSS file
import FacebookLogin from "react-facebook-login";

const LoginModal = ({ show, setShow, setForgetModal }) => {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneTab, setIsPhoneTab] = React.useState("email");
  const [secure, setSecure] = React.useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    email: "Mohsin@gmail.com",
    password: "Zikk@1234",
    phoneNumber: "3425693093",
  });

  const handleGoogleLogin = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse?.credential);
    console.log("decoded::", decoded);
    dispatch(
      onLogin(
        {
          name: decoded?.name,
          email: decoded?.email,
          password: `Zikk@1234${decoded?.sub}`,
          confirmPassword: `Zikk@1234${decoded?.sub}`,
          image: decoded?.picture,
          phoneNumber: decoded?.phone || "",
        },
        (response) => {},
        "social",
        setShow
      )
    );
  };

  const navigate = useNavigate();

  const onHandleChange = (e) =>
    setPayload((pre) => ({ ...pre, [e.target.name]: e.target.value }));

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = { ...payload };
    if (isPhoneTab === "email") {
      delete data.phoneNumber;
      dispatch(onLogin(data, setLoading, false, setShow));
    } else {
      console.log("in phone number submit");
      delete data.email;
      delete data.password;
      console.log("data : " , data)
      // Add "+1" to phone number
      const formattedPhoneNumber = `+1${data.phoneNumber}`;
      data.phoneNumber = formattedPhoneNumber;
      const response = await dispatch(onLogin(data, setLoading, true, setShow));
      setPhoneNumber(formattedPhoneNumber); // Set phone number for OTP verification
      setShowOtpModal(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton className="custom-close-header">
          {/* <Modal.Title>Login</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal-wrapper">
            <Tab.Container
              onSelect={(eventKey) => setIsPhoneTab(eventKey)}
              defaultActiveKey="email"
            >
              <span className="signup-title">Log in</span>
              <Nav
                variant="tabs"
                className="mb-3 mt-3 d-flex justify-content-between border-0"
              >
                <Nav.Item>
                  <Nav.Link eventKey="email">Email</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="phone">Phone Number</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="email">
                  <form onSubmit={onSubmit}>
                    <input
                      required
                      value={payload.email}
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="login-input-field"
                      onChange={onHandleChange}
                    />
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="login-input-container">
                        <input
                          required
                          value={payload.password}
                          name="password"
                          type={secure ? "password" : "text"}
                          placeholder="Password"
                          className="login-input-field"
                          onChange={onHandleChange}
                        />
                        <button
                          type="button"
                          onClick={() => setSecure((p) => !p)}
                          className="decoration-none icon-container px-2"
                        >
                          <i
                            className={
                              secure ? `fa fa-eye` : `fa fa-eye-slash`
                            }
                            aria-hidden="true"
                            style={{ marginRight: "10px" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="border-0 element-custom-btn mb-3"
                    >
                      {loading ? "Loading" : "Continue"}
                    </button>
                    <Link to="#" className="social-login-links">
                      Or connect with social media
                    </Link>
                    <div className="text-center mb-3" style={{display:"flex", justifyContent:"space-between", flexDirection:"column", gap:"3%", alignItems:"center"}}>
                      <FacebookLogin
                        textButton=" Continue with Facebook"
                        size="large"
                        appId="1943117732727713"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={(res) => {}}
                        icon={
                          <i
                            className="fa fa-facebook"
                            style={{ cursor: "pointer" }}
                            aria-hidden="true"
                          />
                        }
                        cssClass="my-facebook-button-class"
                      />
                      <GoogleLoginButton onSuccess={handleGoogleLogin} />
                    </div>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(false);
                        setForgetModal(true);
                      }}
                      style={{ fontFamily: "sans-serif" }}
                      className="forgot-password"
                    >
                      Forgot Password? <span>Reset it</span>
                    </Link>
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="phone">
                  <form onSubmit={onSubmit}>
                    <div className="modal-wrapper p-0">
                      <div style={{display:"flex", marginBottom:"30px", gap:"10px"}}>
                        <input type="text" className="login-code" value="+1" readOnly />
                        <input
                          required
                          value={payload.phoneNumber}
                          name="phoneNumber"
                          type="tel"
                          placeholder="Phone Number"
                          className="login-input-field"
                          pattern="^\d{10}$"
                          title="Please enter a valid phone number (+1 USA code)."
                          onChange={onHandleChange}
                          style={{
                            width:"70%"
                          }}
                    
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="border-0 element-custom-btn mb-3"
                      >
                        {loading ? "Loading" : "Continue"}
                      </button>
                    </div>
                  </form>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
      {/* Include OTPModal here */}
      <OTPModal
        show={showOtpModal}
        setShow={setShowOtpModal}
        phoneNumber={phoneNumber}  
        onSuccessRegister={() => {
          setShowOtpModal(false);
          setShow(false); // Close the login modal on successful registration
        }}
      />
    </>
  );
};

export default LoginModal;
