
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import blog from '../../../assets/images/blog.png';
import career from '../../../assets/images/career.png';
import about from '../../../assets/images/about.png';
import privacy from '../../../assets/images/privacy.jpg';
import terms from '../../../assets/images/terms.jpg';
import {
  bell,
  info,
  location,
  profile,
  question,
  shopping_beg,
  user_profile_img,
} from "../../../assets/images";
import MapModal from "../map-modal";
import LoginModal from "../login-modal";
import NotificationModal from "../notifications-modal";
import SignupModal from "../signup-modal";
import ProfileModal from "../profile-modal";
import { UTILS } from "../../../utils";
import { resetCart } from "../../../store/reducers/cart-slice";
import { resetUser, setLocation } from "../../../store/reducers/user-reducer";
import "./mainSidebar.css";

const MainSideBar = ({ showModal, handleModalClose }) => {
  const { user } = useSelector((s) => s);
  const dispatch = useDispatch();
  const { userInfo } = user;
  const [loginModal, setLoginModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [showSignupModal, setSignupModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);

  return (
    <div className="sidebar-container">
      <Modal
        dialogClassName="custom-modal"
        contentClassName="custom-modal-content"
        show={showModal}
        onHide={handleModalClose}
        className="sidebar-modal"
      >
        <div className="modal-body">
          <div className="user-profile">
            <img
              className="user-profile-img"
              src={userInfo?.image || user_profile_img}
              alt="User Profile"
              style={{width:"50px", height:"50px"}}
            />
            <div className="user-detail">
              <span style={{fontWeight:"bold"}}>
                {userInfo?.fullName || userInfo?.userName?.slice(0, 15) || "Guest"}
              </span>
              <br></br>
              <small>{userInfo?.email}</small>
            </div>
          </div>

          <hr></hr>

          {/* <div className="auth-buttons d-lg-none d-md-none d-sm-block">
            <button className="btn btn-primary" onClick={() => setLoginModal(true)}>
              Log In
            </button>
            <button className="btn btn-secondary" onClick={() => setSignupModal(true)}>
              Sign Up
            </button>
          </div> */}

          <ul className="nav-links">
            {userInfo && (
              <>
                <li>
                  <Link to="/order-history" onClick={handleModalClose} className="nav-link">
                    <img src={shopping_beg} alt="Orders" /> Orders
                  </Link>
                </li>
                <li>
                  <Link to="/payment-processing" onClick={handleModalClose} className="nav-link">
                    <img src={shopping_beg} alt="Payments" /> Payments
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={(e) => { e.preventDefault(); setShowProfileModal(true); }} className="nav-link">
                    <img src={profile} alt="Profile" /> My Details
                  </Link>
                </li>
                {userInfo?.id && (
                  <li>
                    <Link to="#" onClick={(e) => { e.preventDefault(); setShowMapModal(true); }} className="nav-link">
                      <img src={location} alt="Location" /> Delivery Address
                    </Link>
                  </li>
                )}
                {userInfo?.id && (
                  <li>
                    <Link to="#" onClick={(e) => { e.preventDefault(); setNotificationModal(true); }} className="nav-link">
                      <img src={bell} alt="Notifications" /> Notifications
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/blog" onClick={handleModalClose} className="nav-link">
                    <img src={blog} alt="Blog" style={{height:"32px", width:"32px", marginLeft:"0"}} /> Blogs
                  </Link>
                </li>
                <li>
                  <Link to="/career" onClick={handleModalClose} className="nav-link">
                    <img src={career} alt="Career" style={{height:"32px", width:"32px", marginLeft:"0"}} /> Careers
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to="/help" onClick={handleModalClose} className="nav-link">
                <img src={question} alt="Help" /> Help
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleModalClose} className="nav-link">
                <img src={about} alt="About" /> About
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" onClick={handleModalClose} className="nav-link">
                <img src={privacy} alt="Privacy Policy" /> Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms" onClick={handleModalClose} className="nav-link">
                <img src={terms} alt="Terms" style={{height:"32px", width:"32px", marginLeft:"0"}} /> Terms and Conditions
              </Link>
            </li>
          </ul>

          {userInfo && (
            <button
              className="logout-button"
              onClick={() => {
                dispatch(resetUser());
                dispatch(resetCart());
                handleModalClose();
              }}
            >
              <i className="fa fa-sign-out" aria-hidden="true"></i> Log Out
            </button>
          )}
        </div>
      </Modal>

      <LoginModal show={loginModal} setShow={setLoginModal} />
      <SignupModal
        show={showSignupModal}
        setShow={setSignupModal}
        onSuccessRegister={() => {
          setSignupModal(false);
          setLoginModal(true);
        }}
      />
      <ProfileModal
        show={showProfileModal}
        setShow={setShowProfileModal}
        onSuccessRegister={() => setShowProfileModal(false)}
      />
      <NotificationModal
        show={notificationModal}
        onHide={() => setNotificationModal(false)}
      />
      <MapModal
        show={showMapModal}
        latlng={[user?.location?.latitude, user?.location?.longitude]}
        onHide={setShowMapModal}
        onConfirmLocation={async (data) => {
          const [lat, lng] = data;
          const res = await UTILS?._returnAddress(lat, lng);
          dispatch(
            setLocation({
              latitude: lat,
              longitude: lng,
              address: res?.fulladdress,
            })
          );
        }}
      />
    </div>
  );
};

export default MainSideBar;
