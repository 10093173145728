// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5w5PUoCSONXQd3V9E8AqB9Ou09wKCCRI",
  authDomain: "tcart-eea6b.firebaseapp.com",
  projectId: "tcart-eea6b",
  storageBucket: "tcart-eea6b.appspot.com",
  messagingSenderId: "738995038411",
  appId: "1:738995038411:web:8e110aa9bfafb976f1d34e",
  measurementId: "G-SR8X21CSK4",
  databaseURL: 'https://tcart-eea6b.firebaseapp.com',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
