// import React from "react";
// import { Link } from "react-router-dom";

// function TermsAndConditions() {
//   return (
//     <div className="container" style={{width:"70%", marginTop:"40px"}}>
//       <h1>Welcome to Tolo Cart E-ecommerce!</h1>
//       <p style={{marginTop:"40px"}}>
//         <strong>1. Acceptance of Terms:</strong> By accessing and using this
//         website, you agree to comply with and be bound by these terms and
//         conditions.
//       </p>
//       <p>
//         <strong>2. Use of the Website:</strong> You may use this website for
//         browsing and purchasing products. You must not use the website for any
//         illegal or unauthorized purposes.
//       </p>
//       <p>
//         <strong>3. Products and Orders:</strong> We strive to provide accurate
//         product descriptions and pricing. Placing an order signifies your
//         acceptance of the order's total price and the product's features.
//       </p>
//       <p>
//         <strong>4. User Accounts:</strong> You can create an account for a
//         personalized shopping experience. You're responsible for maintaining the
//         confidentiality of your account information.
//       </p>
//       <p>
//         <strong>5. Privacy:</strong> We respect your privacy. Our{" "}
//         <Link to="/privacy-policy">Privacy Policy</Link> outlines how we
//         collect, use, and protect your information.
//       </p>
//       <p>
//         <strong>6. Intellectual Property:</strong> The content on this website
//         is protected by intellectual property laws. You must not reproduce,
//         distribute, or modify any content without our permission.
//       </p>
//       <p>
//         <strong>7. Limitation of Liability:</strong> We're not liable for any
//         damages resulting from your use of the website. Our liability is limited
//         to the extent permitted by law.
//       </p>
//       <p>
//         <strong>8. Governing Law:</strong> These terms are governed by the laws
//         of [Your Jurisdiction]. Any disputes will be subject to the exclusive
//         jurisdiction of the courts in [Your Jurisdiction].
//       </p>
//     </div>
//   );
// }

// export default TermsAndConditions;

import React from "react";
import { useSelector } from "react-redux";

const TermsAndConditions = () => {
  const list = useSelector((state) => state.list.list);
  const contentItem = list.find((item) => item.typeId === 2); // Assuming 2 is the typeId for Terms and Conditions

  if (!contentItem) return <p>No content available</p>;

  return (
    <div className="container mt-4">
      <h1 style={{textAlign:"center"}}>{contentItem.title}</h1>
      <hr
        className='mb-4'
        style={{
          width:"30%",
          position:"relative",
          margin:"auto",
          height:"5px",
          border:"0",
          backgroundColor:"#ff5a00",
          opacity:"1"
        }}
      ></hr>
      <div dangerouslySetInnerHTML={{ __html: contentItem.content }} />
    </div>
  );
};

export default TermsAndConditions;

