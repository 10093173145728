// // About.js
// import React from "react";
// import './about.css'
// import Logo from '../../assets/images/tolocart-logo.png'

// const About = () => {
//   return (
//     <div className="container" style={{width:"70%", marginTop:"40px"}}>
//       <h1>About Tolo Cart</h1>
//       <div className="img-div">
//         <img src={Logo} alt="Logo" />
//       </div>
//       <p>
//         Welcome to Tolo Cart - your one-stop solution for convenient and
//         delicious food delivery. Discover a wide range of cuisines and
//         restaurants in your area, place your order, and have it delivered to
//         your doorstep. Enjoy a hassle-free dining experience with Tolo Cart!
//       </p>
//     </div>
//   );
// };

// export default About;

import React from "react";
import { useSelector } from "react-redux";

const About = () => {
  const list = useSelector((state) => state.list.list);
  const contentItem = list.find((item) => item.typeId === 3); // Assuming 3 is the typeId for About

  if (!contentItem) return <p>No content available</p>;

  return (
    <div className="container mt-4">
      <h1 style={{textAlign:"center"}}>{contentItem.title}</h1>
      <hr
        className='mb-4'
        style={{
          width:"15%",
          position:"relative",
          margin:"auto",
          height:"5px",
          border:"0",
          backgroundColor:"#ff5a00",
          opacity:"1"
        }}
      ></hr>
      <div dangerouslySetInnerHTML={{ __html: contentItem.content }} />
    </div>
  );
};

export default About;

