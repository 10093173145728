import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { setUserInfo, setOtpVerified } from "../../../store/reducers/user-reducer";
import axios from 'axios';

function OTPModal({ show, setShow, phoneNumber, onSuccessRegister }) {
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleVerify = async () => {
    setLoading(true);
    setError(""); // Reset error state

    try {
      const response = await axios.post(
        'https://hamiddawod-001-site2.htempurl.com/api/Account/verify-otp',
        {
          phoneNumber,
          otp
        },
        {
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json-patch+json'
          }
        }
      );

      console.log("OTP verification response:", response.data);

      // Update the Redux state with OTP verification response
      dispatch(setUserInfo(response.data, true)); // Include otpVerified status
      dispatch(setOtpVerified(true)); // Set OTP verification status to true

      onSuccessRegister(); // Call the success handler
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter OTP Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row justify-content-center">
          <OTPInput
            inputStyle={{ height: 50, width: 50 }}
            value={otp}
            onChange={setOTP}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {error && <p className="text-danger mt-2">{error}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)} style={{backgroundColor:"black", border:"none"}}>
          Close
        </Button>
        <Button onClick={handleVerify} disabled={loading} style={{backgroundColor:"#ff5a00", border:"none"}}>
          {loading ? "Verifying..." : "Verify"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OTPModal;
